<template>
  <div>
    <div
      v-if="(!isTeacher || (isTeacher && isEnLang)) && !isOnProfilePage"
      class="form-group d-flex align-items-center unfound-school-wrapper"
    >
      <b-form-checkbox
        v-model="profile.attendingSchool"
        id="isInSchool"
        class="a-checkbox-circle"
      />
      <label for="isInSchool">
        {{
          isTeacher && isEnLang
            ? $t("Učíte/učili ste tento rok?")
            : $t("Študuješ momentálne na škole?")
        }}
      </label>
    </div>

    <b-row
      v-if="profile.attendingSchool || isTeacher || isEnLang"
      align-v="center"
    >
      <b-col
        v-if="profile.attendingSchool || (isTeacher && !isEnLang)"
        cols="12"
      >
        <InputSelect
          v-if="!isEnLang"
          v-model="selectedCity"
          :options="cities"
          :optionLabel="cityOptionLabel"
          :label="`${
            isTeacher
              ? $t('Vyberte mesto vašej školy')
              : $t('Vyber mesto tvojej školy')
          } *`"
          :placeholder="$t('Mesto')"
        />
        <StyledInput
          v-else
          v-model="profile.schoolCreate.name"
          :label="
            (isTeacher ? $t('Názov vašej školy') : $t('Názov tvojej školy')) +
            ' *'
          "
          :placeholder="$t('Názov školy')"
        />
      </b-col>

      <b-col
        v-if="profile.attendingSchool || (isTeacher && !isEnLang)"
        cols="12"
      >
        <label for="schoolAddressAndName" class="label--small">
          {{ $t("Názov ulice a školy") + " *" }}
          <br />
          <span class="text--important">{{
            isTeacher
              ? $t("POZOR! Ak neviete nájsť svoju školu, zadajte najprv ulicu")
              : $t("POZOR! Ak nevieš nájsť svoju školu, zadaj najprv ulicu")
          }}</span>
        </label>
        <InputSelect
          v-if="!isEnLang"
          v-model="selectedSchool"
          id="schoolAddressAndName"
          :options="schoolsOptions"
          :optionLabel="schoolOptionLabel"
          :disabled="!selectedCity"
          :placeholder="$t('Ulica a škola')"
        />
        <StyledInput
          v-else
          v-model="profile.schoolCreate.address"
          :label="
            (isTeacher ? $t('Ulica vašej školy') : $t('Ulica tvojej školy')) +
            ' *'
          "
          :placeholder="$t('Ulica školy')"
        />
      </b-col>

      <b-col cols="12">
        <div
          v-if="!isEnLang"
          class="form-group d-flex align-items-center unfound-school-wrapper mb-2"
        >
          <b-form-checkbox
            v-model="didntFindMySchool"
            id="didntFindMySchool"
            class="a-checkbox-circle"
          />
          <label for="didntFindMySchool">
            {{
              isTeacher
                ? $t("Nenašli ste svoju školu?")
                : $t("Nenašiel/a si svoju školu?")
            }}
          </label>
        </div>
        <StyledInput
          v-else
          v-model="profile.state"
          :label="
            (isTeacher ? $t('Odkiaľ pochádzate?') : $t('Odkiaľ pochádzaš?')) +
            ' *'
          "
          :placeholder="$t('Štát')"
        />
      </b-col>
    </b-row>

    <b-row v-if="didntFindMySchool && !isEnLang" align-v="center">
      <b-col cols="12">
        <p class="form-text text--important">
          {{
            isTeacher
              ? $t(
                  "POZOR! Overte si, či sa škola nenachádza v zozname v predošlej otázke"
                )
              : $t(
                  "POZOR! Over si, či sa škola nenachádza v zozname v predošlej otázke"
                )
          }}
        </p>
      </b-col>

      <b-col cols="12">
        <StyledInput
          v-model="profile.schoolCreate.name"
          :label="
            (isTeacher ? $t('Názov vašej školy') : $t('Názov tvojej školy')) +
            ' *'
          "
          :placeholder="$t('Názov školy')"
        />
      </b-col>

      <b-col cols="12">
        <StyledInput
          v-model="profile.schoolCreate.address"
          :label="
            (isTeacher ? $t('Ulica vašej školy') : $t('Ulica tvojej školy')) +
            ' *'
          "
          :placeholder="$t('Ulica školy')"
        />
      </b-col>

      <b-col cols="12">
        <StyledInput
          v-model="profile.schoolCreate.cityCreate.name"
          :label="
            (isTeacher
              ? $t('Obec/mesto vašej školy')
              : $t('Obec/mesto tvojej školy')) + ' *'
          "
          :placeholder="$t('Obec/mesto školy')"
        />
      </b-col>
    </b-row>

    <b-row v-if="isTeacher" align-v="end">
      <b-col>
        <b-form-group>
          <label>
            {{ $t("Predmety, ktoré vyučujete tento šk. rok") }} *
            <br />
            <span class="font-italic">{{
              $t("Môžete vybrať viacero možností")
            }}</span>
          </label>
          <multiselect
            v-model="userSubjectsModel"
            :options="availableSubjects"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="false"
            :preselect-first="false"
            label="name"
            track-by="name"
            selectLabel=""
            placeholder="Vyberte si predmety"
            selectedLabel="Vybrané"
            deselectLabel="Odstrániť"
          />
        </b-form-group>

        <b-form-group
          v-if="containsOptionOther"
          :label="$t('Iné predmety') + ' *'"
        >
          <b-form-input
            v-model="profile.subjectsOther"
            type="text"
            :placeholder="$t('Iné predmety')"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { set, get, isEmpty } from "lodash";
import axios from "/utils/axios";
import { normalizeEnumEntityResponse } from "/utils/helpers";
import { mapGetters } from "vuex";
import * as Sentry from "@sentry/vue";

const api = {
  schools: (filters) => {
    return axios.get("schools", {
      params: {
        filters,
        populate: ["city", "county"],
        pagination: {
          limit: 1000,
        },
        sort: ["name"],
      },
    });
  },
  cities: (filters) => {
    return axios.get("cities", {
      params: {
        filters,
        pagination: {
          limit: 3000,
        },
        sort: ["name"],
      },
    });
  },
  subjects: (filters) => {
    return axios.get("subjects", {
      params: {
        populate: ["info"],
        pagination: {
          limit: 500,
        },
        filters,
        sort: ["sortOrder", "name"],
      },
    });
  },
};

export default {
  components: {
    StyledInput: () => import("/components/StyledInput.vue"),
    InputSelect: () => import("/components/InputSelect.vue"),
    Multiselect,
  },

  props: {
    profile: { required: true },
    isOnProfilePage: { default: false },
  },

  data() {
    return {
      didntFindMySchool: false,
      schoolsOptions: [],
      schools: [],
      cities: [],
      apiResponses: null,
      selectedCity: null,
      selectedSchool: null,
      availableSubjects: [],
    };
  },

  computed: {
    ...mapGetters("auth", ["isLoggedIn"]),
    isTeacher: function () {
      return this.profile.type === "teacher";
    },
    isEnLang: function () {
      return this.$root.$i18n.locale === "en";
    },
    userSubjectsModel: {
      get() {
        return this.availableSubjects.filter((subject) =>
          this.profile.subjects.includes(subject.slug)
        );
      },
      set(newSelectedOptions) {
        this.profile.subjects = newSelectedOptions.map((option) => option.slug);
      },
    },
    containsOptionOther: function () {
      return Boolean(
        this.userSubjectsModel.find(
          (subject) => subject.slug === "registracia-ine"
        )
      );
    },
  },

  watch: {
    "profile.type": {
      handler: function (newValue, oldValue) {
        this.didntFindMySchool = false;

        if (newValue === "teacher") {
          this.profile.attendingSchool = true;
        } else if (newValue === "student" && oldValue === "teacher") {
          this.profile.attendingSchool = false;
        }
      },
      immediate: true,
    },

    selectedCity: async function (newValue, oldValue) {
      if (!newValue) {
        this.selectedSchool = null;
        return;
      }

      if (oldValue) {
        this.selectedSchool = null;
      }

      const schools = await api.schools({
        $or: [
          {
            id: {
              $eq: get(this.profile, "school.id"),
            },
          },
          {
            city: {
              id: {
                $eq: newValue.id,
              },
            },
          },
        ],
      });

      this.schools = get(schools, "data", []);
      this.schoolsOptions = this.schools;
    },

    selectedSchool: function (newValue) {
      if (newValue) {
        set(this.profile, "schoolConnect.id", newValue.id);
      }
    },
  },

  async created() {
    try {
      if (this.isLoggedIn) {
        await this.$store.dispatch("auth/user");
      }

      const profileSubjects = get(this.profile, "subjects", []);

      this.apiResponses = await Promise.all([
        api.subjects({
          $or: [
            {
              info: {
                slug: {
                  $in: !isEmpty(profileSubjects) ? profileSubjects : [""],
                },
              },
            },
            {
              registrationSubject: true,
            },
          ],
        }),
        api.cities({ type: "import" }),
      ]);

      const [subjects, cities] = this.apiResponses;

      this.availableSubjects = normalizeEnumEntityResponse(subjects);

      this.cities = get(cities, "data", []);

      if (this.profile.school) {
        this.selectedCity = {
          id: this.profile.school.city.id,
          attributes: { ...this.profile.school.city },
        };
        this.selectedSchool = {
          id: this.profile.school.id,
          attributes: { ...this.profile.school },
        };
      }
    } catch (error) {
      Sentry.captureException(error);
      this.$toast.error(error);
    }
  },

  methods: {
    cityOptionLabel(option) {
      return option.attributes.name;
    },

    schoolOptionLabel(option) {
      return `${option.attributes.name}, ${option.attributes.address}`;
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.unfound-school-wrapper {
  height: 40px;
  border-radius: 10px;
  padding-left: var(--a-form-group-padding-left);
}

.label--small {
  font-size: 14px;
  padding-left: var(--a-form-group-padding-left);
}

.text--important {
  color: red;
}

.form-text {
  padding-left: 10px;
  margin-bottom: 30px;
}

/* Multi select custom styles */

::v-deep {
  .multiselect__tags {
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid var(--a-color-blue-border);
    font-size: 1.125rem;
    font-weight: bold;
    padding-left: var(--a-form-group-padding-left);
  }

  .multiselect__placeholder {
    color: #9ca1ae;
  }

  .multiselect__tag {
    background: var(--a-color-primary);
  }

  .multiselect__tag-icon {
    line-height: 24px;

    &:hover {
      background: #004bbb;
    }
  }

  .multiselect__tag-icon:after {
    color: white;
  }
}
</style>
