var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[((!_vm.isTeacher || (_vm.isTeacher && _vm.isEnLang)) && !_vm.isOnProfilePage)?_c('div',{staticClass:"form-group d-flex align-items-center unfound-school-wrapper"},[_c('b-form-checkbox',{staticClass:"a-checkbox-circle",attrs:{"id":"isInSchool"},model:{value:(_vm.profile.attendingSchool),callback:function ($$v) {_vm.$set(_vm.profile, "attendingSchool", $$v)},expression:"profile.attendingSchool"}}),_c('label',{attrs:{"for":"isInSchool"}},[_vm._v(" "+_vm._s(_vm.isTeacher && _vm.isEnLang ? _vm.$t("Učíte/učili ste tento rok?") : _vm.$t("Študuješ momentálne na škole?"))+" ")])],1):_vm._e(),(_vm.profile.attendingSchool || _vm.isTeacher || _vm.isEnLang)?_c('b-row',{attrs:{"align-v":"center"}},[(_vm.profile.attendingSchool || (_vm.isTeacher && !_vm.isEnLang))?_c('b-col',{attrs:{"cols":"12"}},[(!_vm.isEnLang)?_c('InputSelect',{attrs:{"options":_vm.cities,"optionLabel":_vm.cityOptionLabel,"label":`${
          _vm.isTeacher
            ? _vm.$t('Vyberte mesto vašej školy')
            : _vm.$t('Vyber mesto tvojej školy')
        } *`,"placeholder":_vm.$t('Mesto')},model:{value:(_vm.selectedCity),callback:function ($$v) {_vm.selectedCity=$$v},expression:"selectedCity"}}):_c('StyledInput',{attrs:{"label":(_vm.isTeacher ? _vm.$t('Názov vašej školy') : _vm.$t('Názov tvojej školy')) +
          ' *',"placeholder":_vm.$t('Názov školy')},model:{value:(_vm.profile.schoolCreate.name),callback:function ($$v) {_vm.$set(_vm.profile.schoolCreate, "name", $$v)},expression:"profile.schoolCreate.name"}})],1):_vm._e(),(_vm.profile.attendingSchool || (_vm.isTeacher && !_vm.isEnLang))?_c('b-col',{attrs:{"cols":"12"}},[_c('label',{staticClass:"label--small",attrs:{"for":"schoolAddressAndName"}},[_vm._v(" "+_vm._s(_vm.$t("Názov ulice a školy") + " *")+" "),_c('br'),_c('span',{staticClass:"text--important"},[_vm._v(_vm._s(_vm.isTeacher ? _vm.$t("POZOR! Ak neviete nájsť svoju školu, zadajte najprv ulicu") : _vm.$t("POZOR! Ak nevieš nájsť svoju školu, zadaj najprv ulicu")))])]),(!_vm.isEnLang)?_c('InputSelect',{attrs:{"id":"schoolAddressAndName","options":_vm.schoolsOptions,"optionLabel":_vm.schoolOptionLabel,"disabled":!_vm.selectedCity,"placeholder":_vm.$t('Ulica a škola')},model:{value:(_vm.selectedSchool),callback:function ($$v) {_vm.selectedSchool=$$v},expression:"selectedSchool"}}):_c('StyledInput',{attrs:{"label":(_vm.isTeacher ? _vm.$t('Ulica vašej školy') : _vm.$t('Ulica tvojej školy')) +
          ' *',"placeholder":_vm.$t('Ulica školy')},model:{value:(_vm.profile.schoolCreate.address),callback:function ($$v) {_vm.$set(_vm.profile.schoolCreate, "address", $$v)},expression:"profile.schoolCreate.address"}})],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[(!_vm.isEnLang)?_c('div',{staticClass:"form-group d-flex align-items-center unfound-school-wrapper mb-2"},[_c('b-form-checkbox',{staticClass:"a-checkbox-circle",attrs:{"id":"didntFindMySchool"},model:{value:(_vm.didntFindMySchool),callback:function ($$v) {_vm.didntFindMySchool=$$v},expression:"didntFindMySchool"}}),_c('label',{attrs:{"for":"didntFindMySchool"}},[_vm._v(" "+_vm._s(_vm.isTeacher ? _vm.$t("Nenašli ste svoju školu?") : _vm.$t("Nenašiel/a si svoju školu?"))+" ")])],1):_c('StyledInput',{attrs:{"label":(_vm.isTeacher ? _vm.$t('Odkiaľ pochádzate?') : _vm.$t('Odkiaľ pochádzaš?')) +
          ' *',"placeholder":_vm.$t('Štát')},model:{value:(_vm.profile.state),callback:function ($$v) {_vm.$set(_vm.profile, "state", $$v)},expression:"profile.state"}})],1)],1):_vm._e(),(_vm.didntFindMySchool && !_vm.isEnLang)?_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"form-text text--important"},[_vm._v(" "+_vm._s(_vm.isTeacher ? _vm.$t( "POZOR! Overte si, či sa škola nenachádza v zozname v predošlej otázke" ) : _vm.$t( "POZOR! Over si, či sa škola nenachádza v zozname v predošlej otázke" ))+" ")])]),_c('b-col',{attrs:{"cols":"12"}},[_c('StyledInput',{attrs:{"label":(_vm.isTeacher ? _vm.$t('Názov vašej školy') : _vm.$t('Názov tvojej školy')) +
          ' *',"placeholder":_vm.$t('Názov školy')},model:{value:(_vm.profile.schoolCreate.name),callback:function ($$v) {_vm.$set(_vm.profile.schoolCreate, "name", $$v)},expression:"profile.schoolCreate.name"}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('StyledInput',{attrs:{"label":(_vm.isTeacher ? _vm.$t('Ulica vašej školy') : _vm.$t('Ulica tvojej školy')) +
          ' *',"placeholder":_vm.$t('Ulica školy')},model:{value:(_vm.profile.schoolCreate.address),callback:function ($$v) {_vm.$set(_vm.profile.schoolCreate, "address", $$v)},expression:"profile.schoolCreate.address"}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('StyledInput',{attrs:{"label":(_vm.isTeacher
            ? _vm.$t('Obec/mesto vašej školy')
            : _vm.$t('Obec/mesto tvojej školy')) + ' *',"placeholder":_vm.$t('Obec/mesto školy')},model:{value:(_vm.profile.schoolCreate.cityCreate.name),callback:function ($$v) {_vm.$set(_vm.profile.schoolCreate.cityCreate, "name", $$v)},expression:"profile.schoolCreate.cityCreate.name"}})],1)],1):_vm._e(),(_vm.isTeacher)?_c('b-row',{attrs:{"align-v":"end"}},[_c('b-col',[_c('b-form-group',[_c('label',[_vm._v(" "+_vm._s(_vm.$t("Predmety, ktoré vyučujete tento šk. rok"))+" * "),_c('br'),_c('span',{staticClass:"font-italic"},[_vm._v(_vm._s(_vm.$t("Môžete vybrať viacero možností")))])]),_c('multiselect',{attrs:{"options":_vm.availableSubjects,"multiple":true,"close-on-select":false,"clear-on-select":false,"preserve-search":false,"preselect-first":false,"label":"name","track-by":"name","selectLabel":"","placeholder":"Vyberte si predmety","selectedLabel":"Vybrané","deselectLabel":"Odstrániť"},model:{value:(_vm.userSubjectsModel),callback:function ($$v) {_vm.userSubjectsModel=$$v},expression:"userSubjectsModel"}})],1),(_vm.containsOptionOther)?_c('b-form-group',{attrs:{"label":_vm.$t('Iné predmety') + ' *'}},[_c('b-form-input',{attrs:{"type":"text","placeholder":_vm.$t('Iné predmety')},model:{value:(_vm.profile.subjectsOther),callback:function ($$v) {_vm.$set(_vm.profile, "subjectsOther", $$v)},expression:"profile.subjectsOther"}})],1):_vm._e()],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }